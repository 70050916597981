/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/jsx-curly-newline */
import React, { useRef, useState, useCallback, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { useToast } from '../../../../hooks/Toast';
import { handleDashBoardTickets } from '../../api/occurence.api';
import { Container } from '../styles';
import SearchBox from '../../../../components/Search';
import LoadingComponent from '../../../../components/LoadingComponent';
import TicketsTable from '../../components/TicketsTable';

const Occurrence: React.FC = () => {
  const searchBoxRef = useRef<FormHandles>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [occurrenceData, setOccurrenceData] = useState<any[]>([]);
  const { Toastsuccess, ToastError } = useToast();

  const idStore = localStorage.getItem('@pdamodules::storeId');

  const tzoffset = new Date().getTimezoneOffset() * 60000;
  const dateDay = new Date(Date.now() - tzoffset).toISOString().split('T')[0];

  const handleSubmit = useCallback(
    async formData => {
      setIsLoading(true);
      setShowTable(false);
      await handleDashBoardTickets(formData)
        .then(res => {
          console.log(res.data);
          console.log(res.data);
          if (!res.data.length) {
            setOccurrenceData([]);
            ToastError({ message: 'Não foram encontrado ocorrência(s)!' });
          } else {
            setOccurrenceData(res.data);
            Toastsuccess({
              message: `${res.data.length} ocorrência(s) encontrados!`,
            });
            setShowTable(true);
          }
        })
        .catch(err => {
          ToastError({ message: err });
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [ToastError, Toastsuccess]
  );

  const setInitialFields = useCallback(() => {
    searchBoxRef.current?.setFieldValue('initialDate', dateDay);
    searchBoxRef.current?.setFieldValue('finalDate', dateDay);
    handleSubmit({
      initialDate: dateDay,
      finalDate: dateDay,
      loja: idStore,
    });
  }, [dateDay, handleSubmit, idStore]);

  useEffect(() => {
    setInitialFields();
  }, [setInitialFields]);

  return (
    <>
      <Container withOutPadding>
        <SearchBox
          handleSubmit={data => handleSubmit(data)}
          searchBoxRef={searchBoxRef}
          searchDisabled={isLoading}
          title="DashBoad de Ocorrência"
          inputs={[
            {
              name: 'initialDate',
              label: 'Data Inicial:',
              type: 'date',
              defaultValue: dateDay,
              xl: 2,
              lg: 2,
              md: 12,
              sm: 12,
            },
            {
              name: 'finalDate',
              label: 'Data Final:',
              type: 'date',
              defaultValue: dateDay,
              xl: 2,
              lg: 2,
              md: 12,
              sm: 12,
              xs: 12,
            },
          ]}
        />
      </Container>
      {isLoading && <LoadingComponent />}
      {showTable && (
        <TicketsTable
          handleFilter={handleSubmit}
          ticketTableRows={occurrenceData}
          dashboardTable
        />
      )}
    </>
  );
};
export default Occurrence;
